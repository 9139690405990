import { Axios } from 'axios';
import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  fetchGetFile,
  SERVER_ENDPOINT,
} from './index';

export const fetchAllProducts = async (id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/shop/getAllProducts/?id=${id ? id : ''}`,
  );
};

export const fetchProducts = async (page, categories) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/shop/getProducts?page=${page}&categories=${categories}`,
  );
};

export const fetchAllCategories = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/shop/getProductsCategories`);
};

export const fetchCategoryById = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/shop/getCategoryById`);
};

export const addProductToCart = async (
  id,
  quantity,
  zakaut_type,
  zakaut_weight,
  zakaut_weight_por,
) => {
  return await fetchPost(`${SERVER_ENDPOINT}/api/shop/addProductToCart`, {
    id: +id,
    quantity: +quantity,
    zakaut_type,
    zakaut_weight,
    zakaut_weight_por,
  });
};

export const fetchCart = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/shop/getCart`);
};

export const sendOrder = async (data, eventData, customerId) => {
  const lineItems = data.items.map((item) => {
    return {
      product_id: item.id,
      quantity: item.quantity,
      meta_data: [
        {
          key: 'zakaut_type',
          value: item.zakaut_type,
        },
        {
          key: 'zakaut_weight_por',
          value: item.zakaut_weight_por,
        },
      ],
    };
  });

  const zakauts = {};
  if (data.items.length === 1) {
    const theItem = data.items[0];
    // zakauts[`zakaut_${theItem.zakaut_type.toLowerCase()}`] = zakauts[
    //   `zakaut_${theItem.zakaut_type.toLowerCase()}`
    // ]
    //   ? zakauts[`zakaut_${theItem.zakaut_type.toLowerCase()}`] +
    //     theItem.zakaut_weight * theItem.quantity
    //   : theItem.zakaut_weight * theItem.quantity;
    let the_zakaut_type;
    if (theItem.zakaut_type === 'W') the_zakaut_type = 'zakaut_barkan';
    else if (theItem.zakaut_type === 'P') the_zakaut_type = 'zakaut_perno';
    else the_zakaut_type = `zakaut_${theItem.zakaut_type.toLowerCase()}`;

    if (zakauts[the_zakaut_type]) {
      zakauts[the_zakaut_type] =
        zakauts[the_zakaut_type] + theItem.zakaut_weight_por > 1
          ? theItem.zakaut_weight * theItem.zakaut_weight_por * theItem.quantity
          : theItem.zakaut_weight * theItem.quantity;
    } else {
      zakauts[the_zakaut_type] =
        zakauts[the_zakaut_type] + theItem.zakaut_weight_por > 1
          ? theItem.zakaut_weight * theItem.zakaut_weight_por * theItem.quantity
          : theItem.zakaut_weight * theItem.quantity;
    }
  } else {
    // const theItem = data.items[data.items.length - 1];
    // if (typeof theItem.key === 'object') {
    //   Object.keys(theItem.key).forEach((key) => {
    //     zakauts[`zakaut_${theItem.key[key].zakaut_type.toLowerCase()}`] =
    //       zakauts[`zakaut_${theItem.key[key].zakaut_type.toLowerCase()}`]
    //         ? zakauts[`zakaut_${theItem.key[key].zakaut_type.toLowerCase()}`] +
    //           theItem.key[key].zakaut_weight * theItem.key[key].quantity
    //         : theItem.key[key].zakaut_weight * theItem.key[key].quantity;
    //   });
    // } else if (typeof theItem.key === 'string') {

    data.items.forEach((item) => {
      let the_zakaut_type;
      if (item.zakaut_type === 'W') the_zakaut_type = 'zakaut_barkan';
      else if (item.zakaut_type === 'P') the_zakaut_type = 'zakaut_perno';
      else the_zakaut_type = `zakaut_${item.zakaut_type.toLowerCase()}`;

      if (item.zakaut_type) {
        // zakauts[`zakaut_${item.zakaut_type.toLowerCase()}`] = zakauts[
        //   `zakaut_${item.zakaut_type.toLowerCase()}`
        // ]
        //   ? zakauts[`zakaut_${item.zakaut_type.toLowerCase()}`] +
        //     item.zakaut_weight * item.quantity
        //   : item.zakaut_weight * item.quantity;
        if (zakauts[the_zakaut_type]) {
          zakauts[the_zakaut_type] =
            zakauts[the_zakaut_type] + item.zakaut_weight_por > 1
              ? item.zakaut_weight * item.zakaut_weight_por * item.quantity
              : item.zakaut_weight * item.quantity;
        } else {
          zakauts[the_zakaut_type] =
            zakauts[the_zakaut_type] + item.zakaut_weight_por > 1
              ? item.zakaut_weight * item.zakaut_weight_por * item.quantity
              : item.zakaut_weight * item.quantity;
        }
      }
    });
    // }
  }

  const orderData = {
    payment_method: 'bacs',
    payment_method_title: '',
    set_paid: true,
    billing: data.billing_address,
    shipping: data.shipping_address,
    line_items: lineItems,
    shipping_lines: [
      {
        method_id: data.shipping_rates[0]
          ? data.shipping_rates[0].shipping_rates[0].method_id
          : 'free_shipping',
        method_title: data.shipping_rates[0]
          ? data.shipping_rates[0].shipping_rates[0].name
          : 'משלוח חינם',
      },
    ],
    customer_id: customerId,
  };

  return await fetchPost(`${SERVER_ENDPOINT}/api/shop/placeOrder`, {
    orderData,
    eventData,
    zakauts,
  });
};

export const deleteItemFromCart = async (key) => {
  return await fetchPost(
    `${SERVER_ENDPOINT}/api/shop/deleteCartItem?item_key=${key}`,
  );
};

export const updateItemInCart = async (key, quantity) => {
  return await fetchPost(
    `${SERVER_ENDPOINT}/api/shop/updateCartItem?item_key=${key}&item_quantity=${quantity}`,
  );
};

export const fetchOrdersByUserId = async (user_id, perPage, page) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/shop/getUserOrders?user_id=${user_id}${
      perPage ? '&per_page=' + perPage : ''
    }&page=${page}`,
  );
};

export const fetchOrderById = async (order_id) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/shop/getOrderById?order_id=${order_id}`,
  );
};

export const cancelOrderById = async (order_id) => {
  //   return await fetchPut(
  //     `${SERVER_ENDPOINT}/api/shop/cancelOrderById?order_id=${order_id}`,
  //   );
  return await fetchPut(`${SERVER_ENDPOINT}/api/shop/cancelOrderById`, {
    order_id: order_id,
  });
};

// export const cancelOrderById = async (order_id) => {
// 	return await fetchPost(`${SERVER_ENDPOINT}/api/shop/cancelOrderById`, {
// 	  order_id: order_id,
// 	});
//   };

export const fetchAllShopOrders = async () => {
  return await fetchGet(`${SERVER_ENDPOINT}/api/shop/getAllShopOrders`);
};

export const fetchProductsBySearch = async (searchValue, page) => {
  return await fetchGet(
    `${SERVER_ENDPOINT}/api/shop/getProductsBySearch/?search=${searchValue}&page=${page}`,
  );
};
